<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12" v-if="loading">
                <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
            </div>
            <div class="col-lg-12" v-else>
                <div class="card">
                    <div class="card-body">
                        <b-card-title>
                            <h4 class="card-title">Level </h4>
                        </b-card-title>
                        <div class="row">
                            <div class="col-3">
                                <FormulateForm name="createItem" v-model="values" :schema="searchScheme"
                                    @submit="updateItem">
                                    <div>
                                        <p style=" margin-right: 20px;">
                                            Select User Type
                                        </p>
                                        <b-form-select v-model="selectedUserType" :options="user_types"
                                            style="width: 240px; margin-bottom: 20px;">
                                            <template #first>
                                                <b-form-select-option :value="null" disabled>
                                                    <span>-- User Type --</span>
                                                </b-form-select-option>
                                            </template>
                                        </b-form-select>

                                    </div>
                                    <div class="row sm">
                                        <p style="margin-left: 15px; margin-right: 20px;">
                                            Display Banner
                                        </p>
                                        <toggle-button v-model="displayBanner" color="#000000"
                                            :labels="{checked: 'Yes', unchecked: 'No'}" />
                                    </div>
                                    <FormulateInput type="submit">
                                        <span v-if="updatingInfo">
                                            <b-spinner class="vueformulate-loader"></b-spinner>
                                            <span> Updating... </span>
                                        </span>
                                    </FormulateInput>
                                </FormulateForm>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { levels } from "@/config/api/levels";
import Swal from "sweetalert2";
import router from "@/router/index";

export default {
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            loading: false,
            title: "Plan Levels",
            file: undefined,
            displayBanner: null,
            levelId: "",
            selectedUserType: null,
            user_types: [
                "user", "company"
            ],
            items: [
                {
                    text: "Levels ",
                    to: { name: "Levels" },
                },
                {
                    text: "Edit",
                    active: true,
                },
            ],

            updatingInfo: false,
            values: {},
            searchScheme: [
                {
                    index: 1,
                    type: "text",
                    name: "name",
                    label: "Name",
                    "validation-messages": { required: "Level Name is required" },
                    validation: "required",

                },
                {
                    index: 2,
                    type: "number",
                    name: "views_profile",
                    label: "Project Views",
                    "validation-messages": { required: "Project Views is required" },
                    validation: "required",
                    min: "0",
                },
                {
                    index: 3,
                    type: "number",
                    name: "image_limit",
                    label: "Image Limit",
                    "validation-messages": { required: "Image Limit is required" },
                    validation: "required",
                    min: "0",
                },

            ],
        };
    },
    computed: {},
    watch: {},
    mounted() {
        if (this.$route.query.id) {
            this.loading = true;
            this.levelId = this.$route.query.id;
            this.searchLevel();
        }
    },
    methods: {
        updateItem(data) {
            this.updatingInfo = true;
            let api = undefined;

            let updateData = {
                name: data.name,
                user_type: this.selectedUserType,
                features: {
                    views_profile: data.views_profile,
                    image_limit: data.image_limit,
                    display_banner: this.displayBanner,
                }
            };
            if (this.levelId) {
                api = { ...levels.update };
                updateData = {
                    ...updateData,
                    id: this.levelId,
                };
                api.url += this.levelId;
            } else {
                api = { ...levels.create };
            }

            api.data = { ...updateData };

            this.generateAPI(api)
                .then((res) => {
                    console.log(res.data)
                    this.updatingInfo = false;
                    this.success();
                })
                .catch(() => {
                    this.updatingInfo = false;
                    this.failed();
                })
                .finally(() => {

                    setTimeout(() => {
                        this.searchLevel();
                        router.push({ path: "/levels" });
                    }, 1000);
                });
        },
        success() {
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Level has been added",
                showConfirmButton: false,
                timer: 1000,
            });
        },
        failed() {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Please try again",
                showConfirmButton: false,
                timer: 1000,
            });
        },
        searchLevel() {
            if (!this.levelId) return;
            this.loading = true;
            let api = { ...levels.getAll };
            api.params = {
                _id: this.levelId
            }
            this.generateAPI(api)
                .then((res) => {
                    this.loading = false;
                    this.file = undefined;
                    this.values = res.data.plans[0];
                    this.values = {
                        ...this.values,
                        views_profile: this.values.features.views_profile,
                        image_limit: this.values.features.image_limit,
                    }
                    this.displayBanner = res.data.plans[0].features.display_banner
                    this.selectedUserType = res.data.plans[0].user_type
                })
                .catch((err) => {
                    alert("Failed");
                    console.log(err);
                });
        },
    },
};
</script>


